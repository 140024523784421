import axios from 'axios'

export default class ShortAPI {
  constructor() {
    this.client = null
    this.api_url = 'http://127.0.0.1:8787'
  }
  init = () => {
    this.api_token = null
    this.client = axios.create({ baseURL: this.api_url, timeout: 31000})
    return this.client
  }

  getShortUrl = (data) => {
    return this.init().post('/',{query: data},)
  }
}