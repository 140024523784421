import React, {useState} from "react";
import Short from './api/ShortAPI'


import { useParams } from 'react-router-dom';
function App() {
  const { shortID } = useParams()
  const [data, setData] = useState('')
  const api = new Short()
  
  const handleCheck = async () => {
    await api.getShortUrl(shortID).then((res) => {
      console.log(res)
      setData(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }
  // get url parameter (url_short string)
  // check url_short string using API
  // if exist, get user-agent
  // post user agent in API
  // redirect 302
  const ua = navigator.userAgent
  console.log(ua)
  
  return (
    <>
      <button onClick={handleCheck}>Get detail</button>
      {data.target_url}
    </>
  ); 
}

export default App;
